<template>
    <div class="resume">
        <div v-if="!data.documents || !data.documents?.length" class="resume__content">
            <div v-if="data.icon === 'lock'" class="resume__icon">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31.6667 18.3359H8.33333C6.49238 18.3359 5 19.8283 5 21.6693V33.3359C5 35.1769 6.49238 36.6693 8.33333 36.6693H31.6667C33.5076 36.6693 35 35.1769 35 33.3359V21.6693C35 19.8283 33.5076 18.3359 31.6667 18.3359Z" stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.6665 18.3359V11.6693C11.6665 9.45913 12.5445 7.33952 14.1073 5.77671C15.6701 4.21391 17.7897 3.33594 19.9998 3.33594C22.21 3.33594 24.3296 4.21391 25.8924 5.77671C27.4552 7.33952 28.3332 9.45913 28.3332 11.6693V18.3359" stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div v-if="data.icon === 'clock'" class="resume__icon">
                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.0007 37.1693C29.2054 37.1693 36.6673 29.7073 36.6673 20.5026C36.6673 11.2979 29.2054 3.83594 20.0007 3.83594C10.7959 3.83594 3.33398 11.2979 3.33398 20.5026C3.33398 29.7073 10.7959 37.1693 20.0007 37.1693Z" stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M20 10.5V20.5L26.6667 23.8333" stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

            </div>
            <div v-if="data.icon === 'check'" class="resume__icon">
                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.0007 37.1693C29.2054 37.1693 36.6673 29.7073 36.6673 20.5026C36.6673 11.2979 29.2054 3.83594 20.0007 3.83594C10.7959 3.83594 3.33398 11.2979 3.33398 20.5026C3.33398 29.7073 10.7959 37.1693 20.0007 37.1693Z" stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M27.4998 15.5469L17.883 25.1637L13.5117 20.7924" stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div v-if="data.icon === 'user-x'" class="resume__icon">
                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M26.667 35.5V32.1667C26.667 30.3986 25.9646 28.7029 24.7144 27.4526C23.4641 26.2024 21.7684 25.5 20.0003 25.5H8.33366C6.56555 25.5 4.86986 26.2024 3.61961 27.4526C2.36937 28.7029 1.66699 30.3986 1.66699 32.1667V35.5" stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14.1667 18.8333C17.8486 18.8333 20.8333 15.8486 20.8333 12.1667C20.8333 8.48477 17.8486 5.5 14.1667 5.5C10.4848 5.5 7.5 8.48477 7.5 12.1667C7.5 15.8486 10.4848 18.8333 14.1667 18.8333Z" stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M30 13.8359L38.3333 22.1693" stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M38.3333 13.8359L30 22.1693" stroke="#8F9CAC" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>

            <div v-if="data.icon" class="resume__title">
                {{ data.description }}
            </div>
        </div>
        <div v-if="data.documents && data.documents?.length" class="resume__col">
            <div v-if="data.documents" class="resume__docs">
                <div class="resume__docs-title">Документы соискателя</div>

                <div class="resume__docs-list">
                    <VFile v-for="document in data.documents" :key="document?.title"
                    @click="() => onDocumentClick(document)"
                           hideSize
                           :file="{
                                 file_name: document.title,
                                 size: `1 файл`,
                                 filepath: document.path}"
                    />
                </div>
            </div>
            <ExtendedQuestionnaire class="resume__extended" :fields="data.extended_form"/>
        </div>
    </div>
</template>

<script setup>
import VFile from '@/components/UI/VFileImage'
import ExtendedQuestionnaire from '@/components/ResponseDetailCard/ExtendedQuestionnaire'
import api from "@/api/api";

const props = defineProps({
    data: Object
})

const openFile = async (document) => {
    const fileUrl = document.path
    const response = await api.get(fileUrl, {responseType: 'blob'});
    let link = document.createElement('a');
    link.href = URL.createObjectURL(response)
    link.download = document.title
    link.setAttribute('target','_blank')
    link.click();
}

const onDocumentClick = (document) => {
    openFile(document)
}
</script>

<style scoped lang="scss">
.resume {
    width: 100%;
    height: 429px;
    display: flex;
    justify-content: center;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__col {
        display: flex;
        flex-direction: column;
    }


    &__docs {
        width: 100%;
        padding: 22px 24px;
    }

    &__docs-title {
        font-size: 16px;
        font-weight: 500;
    }

    &__docs-list {
        margin-top: 16px;
        display: grid;
        grid-template-columns: 187px 187px 187px 187px;
        grid-column-gap: 12px;
        grid-row-gap: 12px;
    }

    &__title {
        max-width: 266px;
        text-align: center;
        margin-top: 12px;
        font-size: 16px;
        font-weight: 500;
    }

    &__subtitle {
        margin-top: 12px;
        padding: 4px 8px;
        border-radius: 45px;
        display: flex;
        background-color: $light;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        span {
            margin-left: 6px;
            font-weight: 500;
        }
    }
}
</style>
